<template>
    <div>
        <div class="f-c-b">
            <el-page-header @back="back" :content="headerContent"></el-page-header>
            <el-button v-show="isShowDeleBtn" @click="removeForumRecruit" type="primary"><i
                    class="el-icon-delete"></i></el-button>
        </div>
        <el-form class="form" label-width="100px" ref="positionForm" :model="positionForm" :rules="positionFormRules">
            <el-form-item label="职位名称：" prop="position">
                <ChineseCharactersInput :modelValue="positionForm.position" :placeholderValue="'请输入职位名称：'"
                    :disabledValue="disabledInput" :maxlength="'30'" @getValue="getValue" :type="'position'">
                </ChineseCharactersInput>
            </el-form-item>
            <el-form-item label="薪资范围：" prop="salary1">
                <div class="f-c">
                    <el-input v-model="positionForm.salary1" :disabled="disabledInput" placeholder="请输入薪资"
                        @input="positionForm.salary1 = positionForm.salary1.replace(/^(0+)|[^\d]+/g, '')"
                        maxlength="10"></el-input>
                    <span style="margin:0 50px">~</span>
                    <el-input v-model="positionForm.salary2" :disabled="disabledInput" placeholder="请输入薪资"
                        @input="positionForm.salary2 = positionForm.salary2.replace(/^(0+)|[^\d]+/g, '')"
                        maxlength="10"></el-input>
                </div>
            </el-form-item>
            <el-form-item label="岗位职责：" prop="postContent">
                <ChineseCharactersInput :modelValue="positionForm.postContent" :placeholderValue="'请输入岗位职责：'"
                    :disabledValue="disabledInput" :maxlength="'3000'" :inputType="'textarea'" @getValue="getValue"
                    :type="'postContent'">
                </ChineseCharactersInput>
            </el-form-item>
        </el-form>
        <div class="Two-btn f-c-a" v-if="queryType == 'publish'">
            <el-button type="primary" round @click.prevent="publicPosition('positionForm', 2)">保存</el-button>
            <el-button type="primary" round @click.prevent="publicPosition('positionForm', 1)">发布</el-button>
        </div>
        <template v-else>
            <div class="One-btn f-c-a" v-if="PositionDetail.deleteFlag == 'N'">
                <template v-if="enterpriseStateInfo.userPermission.p_m == 'W'">
                    <el-button type="primary" v-if="isShowEditBtn" round @click.prevent="handleEditBtn">编辑</el-button>
                    <el-button type="primary" v-else round
                        @click.prevent="reviseForumRecruit('positionForm', 'N')">发布</el-button>
                </template>
            </div>
            <!-- 未发布状态 -->
            <template v-else>
                <template v-if="enterpriseStateInfo.userPermission.p_m == 'W'">
                    <div class="Two-btn f-c-a" v-if="isHandleUnpublishEditBtn">
                        <el-button type="primary" round @click.prevent="handleEditBtnUnpublished">编辑</el-button>
                        <el-button type="primary" round @click.prevent="DirectRelease">直接发布</el-button>
                    </div>
                    <div class="Two-btn f-c-a" v-else>
                        <el-button type="primary" round
                            @click.prevent="reviseForumRecruit('positionForm', 'S')">保存</el-button>
                        <el-button type="primary" round
                            @click.prevent="reviseForumRecruit('positionForm', 'N')">发布</el-button>
                    </div>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import { positionFormRules } from '@/utils/FormRules';
import { UrlDecodeType } from '@/mixins/UrlDecodeMixin';
export default {
    name: 'RecruitPublish',
    mixins: [UrlDecodeType],
    data() {
        return {
            headerContent: '',
            positionForm: {
                position: '',
                salary1: '',
                salary2: '',
                postContent: ''
            },
            disabledInput: false,
            positionFormRules,
            PositionDetail: {},//职位详情
            isShowEditBtn: false,//是否显示编辑按钮
            isShowDeleBtn: false,//是否显示删除按钮
            isHandleUnpublishEditBtn: true,//是否显示未发布编辑按钮
            // 是否显示编辑按钮判断p_m是否为W
            enterpriseStateInfo: this.$getStorage('enterpriseStateInfo'),
            IpAddressInfo: {}
        }
    },
    created() {
        if (this.queryType == 'publish') {
            this.headerContent = '发布'
            this.disabledInput = false
        }
        if (this.$getStorage('PositionDetail')) {
            this.disabledInput = true
            let { position, salary1, salary2, postContent } = this.$getStorage('PositionDetail')
            this.positionForm = {
                position, salary1, salary2, postContent
            }
            this.isShowEditBtn = true
            this.headerContent = '职位详情'
            this.PositionDetail = this.$getStorage('PositionDetail')
        }
        this.$CommonRequests.getIpAddress().then((res) => {
            if (res.code == 200) {
                this.IpAddressInfo = res.data
            }
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
            this.$removeStorage('PositionDetail')
        },
        getValue(data) {
            this.positionForm[data.type] = data.value
        },
        getParmas(saveState) {
            let that = this, { position, salary1, salary2, postContent } = that.positionForm,
                { ip, province, city, districts } = that.IpAddressInfo;
            return {
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                saveState,
                position,
                salary1: Number(salary1),
                salary2: Number(salary2),
                postContent,
                postLocation: province + '*' + city + '*' + districts,//来源
                sourceIp: ip//发帖来源IP
            }
        },
        // 企业用户添加职位招聘接口
        addForumRecruit(type) {
            let that = this;
            that.$http.addForumRecruit(that.getParmas(type)).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.msg, 'success', 1200);
                    that.$common.DelayedBack();
                    that.$removeStorage('PositionDetail')
                }
            })
        },
        // 保存或发布职位
        publicPosition(form, type) {
            let that = this, { position, salary1, salary2 } = that.positionForm;
            if (Number(salary1) > Number(salary2)) {
                return that.$common.message('薪资范围填写错误！', 'error', 1500)
            }
            if (type == 2) {
                if (!position) {
                    return that.$common.message('请输入职位名称！', 'warning')
                }
                that.$confirm(`${that.isHandleUnpublishEditBtn == true ? '确定保存新职位？' : '确定保存职位信息？'}`, '提示').then(() => {
                    that.addForumRecruit(type)
                }).catch(() => { })

            } else {
                that.$refs[form].validate((valid) => {
                    if (valid) {
                        if (!salary2) {
                            return that.$common.message('请输入薪资范围！', 'warning');
                        }
                        that.$confirm(`${that.isHandleUnpublishEditBtn == true ? '确定发布新职位？' : '确定发布该职位？'}`, '提示').then(() => {
                            that.addForumRecruit(type)
                        }).catch(() => { })
                    }
                })
            }
        },
        // 已发布情况
        // 用户点击编辑
        handleEditBtn() {
            this.isShowEditBtn = false;//隐藏编辑按钮
            this.disabledInput = false;
            this.isShowDeleBtn = true;//显示删除按钮
            this.headerContent = '修改职位'

        },
        // 获取修改参数
        getReviseParams(saveState) {
            let that = this, { postId, lastModifyDate } = that.PositionDetail, { position, salary1, salary2, postContent } = that.positionForm,
                { ip, province, city, districts } = that.IpAddressInfo;
            return {
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                postId, lastModifyDate, position,
                salary1: Number(salary1),
                salary2: Number(salary2),
                postContent,
                saveState,
                postLocation: province + '*' + city + '*' + districts,
                sourceIp: ip
            }
        },
        // 用户修改供应求购帖子接口
        reviseForumRecruit(form, type) {
            let that = this, { salary1, salary2 } = that.positionForm;
            if (Number(salary1) > Number(salary2)) {
                return that.$common.message('薪资范围填写错误！', 'error', 1500)
            }
            that.$refs[form].validate((valid) => {
                if (valid) {
                    that.$confirm('确定更新新职位？', '提示').then(() => {
                        that.$http.reviseForumRecruit(that.getReviseParams(type)).then(res => {
                            if (res.code == 200) {
                                that.$common.message(res.msg, 'success', 1200);
                                that.$common.DelayedBack();
                                that.$removeStorage('PositionDetail')
                            }
                        })
                    }).catch(() => { })
                }
            })
        },
        // 用户移除供应求购帖子接口
        removeForumRecruit() {
            let that = this, { postId } = that.PositionDetail
            that.$confirm('确定删除该职位？', '提示').then(() => {
                that.$http.removeForumRecruit({
                    postUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    postId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1200);
                        that.$common.DelayedBack();
                        that.$removeStorage('PositionDetail')
                    }
                })
            }).catch(() => { })
        },
        // 未发布情况（未发布到社区那块）
        DirectRelease() {
            let that = this;
            let { salary1, salary2, postContent } = that.positionForm;
            if (!salary1 || !salary2 || !postContent) {
                return this.$common.message('请填写完整信息！', 'warning', 1500)
            }
            that.$confirm('确定发布该职位？', '提示').then(() => {
                that.$http.reviseForumRecruit(that.getReviseParams('N')).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1200);
                        that.$common.DelayedBack();
                        that.$removeStorage('PositionDetail')
                    }
                })

            }).catch(() => { })
        },
        handleEditBtnUnpublished() {
            this.disabledInput = false;
            this.isShowDeleBtn = true;//显示删除按钮
            this.isHandleUnpublishEditBtn = false
            this.headerContent = '修改职位'
        }
    },
}

</script>
<style lang='less' scoped>
.form {
    margin-top: 30px;
}
</style>
